import LOGIN from '../gql/login.gql'
import GET_ME from '../gql/getMe.gql'
import * as Sentry from '@sentry/react'
import { REFERRER_URL_PARAM } from '../utils/constants.js'
import { GoogleLogin } from '@react-oauth/google'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useApolloClient, useMutation } from '@apollo/client'

export const LoginButton = ({ width }) => {
	const [search] = useSearchParams()
	const referrerId = search.get(REFERRER_URL_PARAM)

	const apolloClient = useApolloClient()
	const [login] = useMutation(LOGIN)

	const navigate = useNavigate()
	const from = useLocation().state?.from?.pathname

	return (
		<GoogleLogin
			shape='pill'
			text='signin'
			width={width}
			onSuccess={async (googleResponse) => {
				await apolloClient.clearStore()
				await login({
					variables: {
						token: googleResponse.credential,
						referrerId,
					},
					update: (cache, { data }) => {
						if (data) {
							// We could refetch, but this is a little faster
							// FIX: It doesn't seem to re-render the `GET_ME` query
							// run by the landing page, for auto-navigation?
							// Seems like it should. That's okay, we'll do it below.
							// Arguably better so we can account for `from` anyway.
							cache.writeQuery({
								query: GET_ME,
								data: { me: data.login },
							})
						}
					},
					onCompleted: () => {
						navigate(from || '/app', { replace: true, state: undefined })
					},
				})
			}}
			onError={() => Sentry.captureException(new Error('Google login error'))}
		/>
	)
}
