import { useQuery } from '@apollo/client'
import GET_MY_CURRENT_WANNA from '../gql/getMyCurrentWanna.gql'

export const useMyCurrentWanna = () => {
	const { data } = useQuery(GET_MY_CURRENT_WANNA)
	// NOTE: It's important to null-safe access on `me`.
	// If we cleared our session cookie (or it expired, I presume) then server will
	// respond with `{ data: { me: null } }` here, and accessing `me.currentWanna`
	// will crash the app before `NetworkContainer` can read the 'Not authenticated' error and redirect to '/'.
	return data?.me?.currentWanna
}
