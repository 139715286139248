import ReactDOM from 'react-dom/client'
import { App } from './App.jsx'
import './assets/css/index.css'

// Force-refresh all windows when the new service worker (and its resources) takes over.
// Prevents blank page when I push client<->server breaking changes and the old client interacts with the new server.
// Which is frequently, lol.
// From https://vite-pwa-org.netlify.app/guide/auto-update.html#automatic-reload
// And  https://vite-pwa-org.netlify.app/guide/inject-manifest.html#auto-update-behavior
// May be slightly jarring for the user, but it's the most surefire solution.
// I could post a message for the UI to display a refresh button,
// but I suspect that'd require me to handle the likely errors that prevent the app from rendering anything at all.
import { registerSW } from 'virtual:pwa-register'
registerSW({ immediate: true })

const rootEl = document.getElementById('root')
ReactDOM.createRoot(rootEl).render(<App />)
