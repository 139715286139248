import {
	Avatar,
	BottomNavigation,
	BottomNavigationAction,
	Paper,
	Stack,
	styled,
	useTheme,
} from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import ChatIcon from '@mui/icons-material/Chat'
import MoodIcon from '@mui/icons-material/Mood'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useMe } from '../hooks/useMe'

export const Nav = () => {
	const theme = useTheme()
	const me = useMe()
	const navigate = useNavigate()
	const location = useLocation()

	const navs = ['circles', 'friends', 'iwanna', 'wannas', 'settings']
	const pathElements = location.pathname.split('/')
	const navElement = pathElements[2]
	const currentNav = navs.indexOf(navElement)

	return (
		<Stack height='100%'>
			<Box
				flex={1}
				sx={{ overflow: 'auto' }}>
				<Outlet />
			</Box>
			<Paper
				elevation={4}
				square>
				<BottomNavigation
					value={currentNav}
					onChange={(_, val) => navigate(navs[val])}>
					<SkinnyBottomNavAction icon={<WorkspacesIcon />} />
					<SkinnyBottomNavAction icon={<MoodIcon />} />
					<SkinnyBottomNavAction icon={<AddCircleIcon />} />
					<SkinnyBottomNavAction icon={<ChatIcon />} />
					<SkinnyBottomNavAction
						icon={
							<Avatar
								src={me?.pic}
								sx={{
									width: '32px',
									height: '32px',
									border:
										currentNav === 4
											? `2px solid ${theme.palette.primary.main}`
											: undefined,
								}}
							/>
						}
					/>
				</BottomNavigation>
			</Paper>
		</Stack>
	)
}

const SkinnyBottomNavAction = styled(BottomNavigationAction)({
	minWidth: 0,
})
