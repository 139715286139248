import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import { LoginButton } from '../../components/LoginButton.jsx'
import { useEffect } from 'react'
import { useMe } from '../../hooks/useMe.js'
import { InteractiveDemo } from './InteractiveDemo.jsx'
import { useIsDemo } from '../../hooks/useIsDemo.js'
import { useNavigate } from 'react-router'
import {
	Chat,
	LocalFlorist,
	RocketLaunch,
	Visibility,
} from '@mui/icons-material'

export const Landing = () => {
	const me = useMe()
	const navigate = useNavigate()
	const isDemo = useIsDemo()

	useEffect(() => {
		if (me) navigate('/app', { replace: true })
	}, [me?.id])

	return (
		<Stack
			bgcolor='primary.main'
			justifyContent='center'
			alignItems='center'
			minHeight='100%'>
			<Stack
				color='white'
				alignItems='center'
				justifyContent='center'
				p={2}
				gap={2}>
				<Stack
					alignItems='center'
					textAlign='center'>
					{/* <HorizontalLogo style={{ width: '79%', maxWidth: '300px' }} /> */}
					<Typography variant='h4'>
						streamline your social spontaneity
					</Typography>
				</Stack>

				<InteractiveDemo />

				<Typography
					variant='caption'
					my={-1}>
					explore the interactive demo above, then...
				</Typography>

				{!isDemo && (
					<Stack alignItems='center'>
						<LoginButton width='282px' />
					</Stack>
				)}

				<List
					disablePadding
					sx={{ marginTop: -1 }}>
					<Callout
						icon={<Visibility />}
						text="see who's free"
					/>
					<Callout
						icon={<RocketLaunch />}
						text='broadcast open invites'
					/>
					<Callout
						icon={<Chat />}
						text='coordinate in one place'
					/>
					<Callout
						icon={<LocalFlorist />}
						text='grow your social circle'
					/>
				</List>
			</Stack>
		</Stack>
	)
}

const Callout = ({ icon, text }) => (
	<ListItem disablePadding>
		<ListItemIcon sx={{ color: 'white', minWidth: 0, mr: 2 }}>
			{icon}
		</ListItemIcon>
		<ListItemText
			primary={text}
			primaryTypographyProps={{ variant: 'body2' }}
		/>
	</ListItem>
)
