import { useMutation, useQuery } from '@apollo/client'
import {
	AppBar,
	Button,
	Toolbar,
	Stack,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@mui/material'
import { useParams } from 'react-router'
import { GoBackButton } from '../../components/GoBackButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import GET_WANNA_HEADER from '../../gql/getWannaHeader.gql'
import SHARE_WANNA from '../../gql/shareWanna.gql'
import { Chat } from './Chat.jsx'
import { ShareButton } from '../../components/ShareButton.jsx'
import { useMe } from '../../hooks/useMe'
import PersonAdd from '@mui/icons-material/PersonAdd'
import { useEffect, useState } from 'react'
import { UserSelector } from '../../components/UserSelector'

export const Wanna = () => {
	const me = useMe()
	const [isInviteOpen, setIsInviteOpen] = useState(false)
	const wannaId = useParams().id

	const { data: { wanna } = {} } = useQuery(GET_WANNA_HEADER, {
		variables: {
			id: wannaId,
		},
	})
	// TODO: optimisticResponse
	const [shareWanna] = useMutation(SHARE_WANNA)
	const [inviteUserIds, setInviteUserIds] = useState([])
	// TODO: this feels so hacky everywhere we use UserSelector; must be better way
	useEffect(() => {
		if (wanna) {
			setInviteUserIds(wanna.invitations.map((inv) => inv.user.id))
		}
	}, [wanna])

	return (
		<Stack
			height='100%'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>{wanna?.activity || ''}</ToolbarTitle>
					{(wanna?.creator.id === me?.id || wanna?.shareable) && (
						<>
							<IconButton
								color='white'
								onClick={() => setIsInviteOpen(true)}>
								<PersonAdd sx={{ color: 'white' }} />
							</IconButton>
							<ShareButton
								text={`Wanna ${wanna?.activity}?`}
								link={window.location.href}
							/>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Chat wannaId={wannaId} />
			<Dialog
				open={isInviteOpen}
				onClose={() => setIsInviteOpen(false)}>
				<DialogTitle>share</DialogTitle>
				<UserSelector
					selectedUserIds={inviteUserIds}
					onSelected={(userIds) => {
						userIds.forEach((userId) => {
							shareWanna({
								variables: {
									wannaId,
									userId,
								},
							})
						})
					}}
				/>
				<DialogActions>
					<Button onClick={() => setIsInviteOpen(false)}>done</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	)
}
