import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Alert, LinearProgress, Snackbar } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import useAppBarHeight from '../hooks/useAppBarHeight.js'
import { useLocation, useNavigate } from 'react-router'
import {
	useApolloClient,
	useApolloNetworkStatus,
} from '../hooks/useApolloClient.js'
import { useIsDemo } from '../hooks/useIsDemo.js'
import { useDemoClient } from '../features/landing/useDemoClient.js'

export const NetworkContainer = ({ children }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const appBarHeight = useAppBarHeight()
	const [error, setError] = useState(null)

	const isDemo = useIsDemo()

	const apolloClient = isDemo ? useDemoClient() : useApolloClient()
	const { numPendingQueries, numPendingMutations, queryError, mutationError } =
		useApolloNetworkStatus()

	const onError = useCallback(
		({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach((err) => {
					const formattedErrorMessage = `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
                    console.error(formattedErrorMessage)
					Sentry.captureException(err, { formattedErrorMessage })
				})
			}

			if (networkError) {
				const formattedErrorMessage = `[Network error (status code: ${networkError.statusCode})]: ${networkError.message}`
                console.error(formattedErrorMessage)
				Sentry.captureException(networkError, { formattedErrorMessage })
			}

			if (
				graphQLErrors?.some((err) => isUnauthenticated(err)) ||
				isUnauthenticated(networkError)
			) {
				// Rely on server-side auth-protected routes, and just redirect when we hit one we can't access.
				// Single source of truth compared to also checking auth client-side.
				// NOTE: `clearStore` first so the landing page doesn't load a cached `me`,
				// think we're logged in, and redirect to the app. Thus causing an infinite loop.
				apolloClient.clearStore().then(() => navigate('/'))
			}

			setError(graphQLErrors?.[0] ?? networkError)
		},
		[setError]
	)

	useEffect(() => {
		if (queryError ?? mutationError) {
			onError(queryError ?? mutationError)
		}
	}, [queryError, mutationError])

	return (
		<ApolloProvider client={apolloClient}>
			{(numPendingQueries > 0 || numPendingMutations > 0) && (
				<LinearProgress
					sx={{
						zIndex: 11, // Otherwise will be underneath any children with `relative` position. Not relevant rn but playing it safe.
						position: 'absolute',
						top: location.pathname === '/' ? 0 : appBarHeight - 3,
						height: '3px',
						width: '100%',
						backgroundColor: 'transparent',
						'& .MuiLinearProgress-bar': {
							backgroundColor: 'rgb(173, 204, 255)',
						},
					}}
				/>
			)}
			<Snackbar
				open={error != null}
				onClose={() => setError(null)}
				sx={{ position: 'absolute' }}
				autoHideDuration={2000}>
				<Alert
					onClose={() => setError(null)}
					severity='error'
					sx={{ width: '100%' }}>
					{error ? formatError(error) : null}
				</Alert>
			</Snackbar>
			{children}
		</ApolloProvider>
	)
}

const formatError = (error) => {
	if (
		error.toString().includes('Failed to fetch') ||
		error.toString().includes('ERR_INTERNET_DISCONNECTED')
	) {
		return 'Check your internet connection'
	} else if (isUnauthenticated(error)) {
		return 'Please log in again 🙂'
	} else {
		return `Something went wrong 🤔 I've been notified 🙂`
	}
}

const isUnauthenticated = (error) =>
	error.message === 'Not authenticated' || error.statusCode === 401
