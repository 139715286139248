import { useQuery } from '@apollo/client'
import GET_INVITED_WANNAS from '../gql/getInvitedWannas.gql'
import ON_WANNA_CREATED from '../gql/onWannaCreated.gql'
import { useMessagesSubscription } from './useMessagesSubscription'
import { useEffect } from 'react'

export const useInvitedWannas = () => {
	const { data, subscribeToMore } = useQuery(GET_INVITED_WANNAS, {})
	const me = data?.me

	useEffect(() => {
		return subscribeToMore({
			document: ON_WANNA_CREATED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev
				const newWanna = subscriptionData.data.wannas

				return {
					...prev,
					me: {
						...prev.me,
						invitedWannas: [newWanna, ...prev.me.invitedWannas].filter(
							(wanna, index, self) =>
								self.findIndex((w) => w.id === wanna.id) === index
						),
					},
				}
			},
		})
	}, [])

	const wannas =
		me?.invitedWannas.filter((wanna) => wanna.closed === null) ?? []

	useMessagesSubscription(wannas.map((wanna) => wanna.id))

	return wannas
}
