import { ApolloClient, createHttpLink, from, split } from '@apollo/client'
import { onError as onErrorLink } from '@apollo/client/link/error/index.js'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions/index.js'
import { getMainDefinition } from '@apollo/client/utilities/index.js'
import { LocalStorageWrapper, persistCacheSync } from 'apollo3-cache-persist'
import { createClient } from 'graphql-ws'
import { useEffect, useMemo, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { usePageVisibility } from 'react-page-visibility'
import { createCache } from '../lib/apolloCache'

const { link: networkStatusLink, useApolloNetworkStatus } =
	createNetworkStatusNotifier()
export { useApolloNetworkStatus }

export function useApolloClient() {
	const isVisible = usePageVisibility()
	const [triggered, setTriggered] = useState(true) // Init to true so we don't refetch during initial load

	const client = useMemo(() => {
		const httpLink = createHttpLink({
			uri: import.meta.env.VITE_API_URL + '/graphql',
			credentials: 'include',
			// fetchOptions: {
			// 	// Don't abort requests if the initiating page is unloaded before it completes.
			// 	// TODO: I think relevant to updating a user's status/presence in a wanna? Confirm that's not broken now.
			// 	// But it makes graphql requests not appear individually in the Network tab.
			// 	// And it seems to break the networkStatusLink (never reports pending queries or mutations).
			// 	// And might be a premature optimization. As far as performance, seems unnecessary in HTTP/2 (the default)
			// 	// because it can already share connections across requests.
			// 	keepalive: true,
			// },
		})
		const wsLink = new GraphQLWsLink(
			createClient({
				url: import.meta.env.VITE_WS_URL + '/graphql',
			})
		)
		const cache = createCache()
		persistCacheSync({
			cache,
			storage: new LocalStorageWrapper(window.localStorage),
			debug: true,
			trigger: 'write',
		})
		return new ApolloClient({
			connectToDevTools: true,
			cache: cache,
			defaultOptions: {
				watchQuery: {
					fetchPolicy: 'cache-and-network',
				},
			},
			link: from([
				networkStatusLink,
				split(
					({ query }) => {
						const definition = getMainDefinition(query)
						return (
							definition.kind === 'OperationDefinition' &&
							definition.operation === 'subscription'
						)
					},
					wsLink,
					httpLink
				),
			]),
		})
	}, [])

	useEffect(() => {
		if (isVisible && !triggered) {
			// TODO is this necessary? Will subscriptions update in the background or upon coming to the foreground?
			client.refetchQueries({
				include: 'active',
			})
			// Only trigger once per visibility change
			setTriggered(true)
		} else if (!isVisible) {
			setTriggered(false)
		}
	}, [isVisible, client])

	return client
}
