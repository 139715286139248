import dayjs from 'dayjs'

let startingMessageId = 100

export const circles = [
	{
		__typename: 'Circle',
		id: '456',
		name: 'cool cats club',
		private: false,
	},
]

export const users = {
	jeff: {
		__typename: 'User',
		id: 'jeff',
		name: 'Jeff',
		pic: '/demo/jeff.webp',
		status: null,
		wannas: [],
		connections: ['james', 'nick', 'sparky'].map((id) => ({
			__typename: 'User',
			id,
		})),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
			{
				...circles[0],
				members: undefined,
			},
		],
	},
	james: {
		__typename: 'User',
		id: 'james',
		name: 'James',
		pic: '/demo/james.webp',
		status: null,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	nick: {
		__typename: 'User',
		id: 'nick',
		name: 'Nick',
		pic: '/demo/nick.webp',
		status: null,
		wannas: [],
		connections: ['sparky', 'james'].map((id) => ({ __typename: 'User', id })),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	sparky: {
		__typename: 'User',
		id: 'sparky',
		name: 'Sparky',
		pic: '/demo/sparky.webp',
		status: null,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
}

export const me = {
	__typename: 'User',
	id: 'me',
	name: 'Lara Croft',
	pic: '/demo/lara.webp',
	status: null,
	connections: [
		// friends
		users.nick,
		users.jeff,
		users.james,
		// circle members
		users.sparky
	],
}

users['me'] = me

// TODO: add more friends for this
circles[0].members = [users.jeff, users.sparky, me]

export const friends = [users.jeff, users.james, users.nick, users.sparky]

export const wannas = [
	{
		id: 'demo_wanna_1',
		activity: 'movie night',
		created: hoursOldDate(3),
		startTime: dayjs().add(2, 'hour').unix(),
		closed: null,
		shareable: true,
		creator: users.jeff,
		invitations: [
			{
				id: 1,
				user: me,
				created: minutesOldDate(31),
				status: null,
				addedBy: me,
				rsvp: 'YES',
			},
			{
				id: 2,
				user: users.jeff,
				created: minutesOldDate(30),
				status: 'PRESENT',
				addedBy: me,
				rsvp: 'YES',
			},
			{
				id: 3,
				user: users.james,
				created: minutesOldDate(30),
				status: null,
				addedBy: me,
				rsvp: null,
			},
			{
				id: 4,
				user: users.nick,
				created: minutesOldDate(30),
				status: 'TYPING',
				rsvp: null,
			},
		].map((inv) => ({ ...inv, __typename: 'Invitation' })),
		messages: _makeConvo(
			[
				{ jeff: 'What movie should we watch?' },
				{ me: 'How about The Matrix?' },
				{ jeff: 'I love that movie!' },
				{ nick: 'I can bring popcorn' },
				{ james: 'I have a projector' },
				{ sparky: 'I can bring drinks' },
				{ me: 'Sounds like a plan!' },
			],
			{ timeAdded: 69 }
		),
	},
	{
		id: '1239874kljnsdf',
		activity: 'pizza-palooza',
		created: hoursOldDate(1),
		startTime: dayjs().add(3, 'hour').unix(),
		closed: null,
		shareable: true,
		creator: users.jeff,
		invitations: [
			{
				id: 10,
				user: me,
				created: minutesOldDate(31),
				status: null,
				addedBy: users.jeff,
				rsvp: null,
			},
			{
				id: 12,
				user: users.jeff,
				created: minutesOldDate(30),
				status: 'TYPING',
				addedBy: me,
				rsvp: 'YES',
			},
			{
				id: 13,
				user: users.james,
				created: minutesOldDate(30),
				status: 'PRESENT',
				addedBy: me,
				rsvp: null,
			},
			// {
			// 	id: 15,
			// 	user: users.sparky,
			// 	status: 'TYPING',
			// 	addedBy: users.jeff,
			// 	created: Date.now(),
			// 	rsvp: 'YES',
			// },
		].map((inv) => ({ ...inv, __typename: 'Invitation' })),
		messages: _makeConvo(
			[
				{ jeff: "Let's hit that Domino's deal" },
				{ james: "Can they cut it into 6 slices? I can't finish 8" },
				{ sparky: 'Is catnip a topping? 😺🍕' },
			],
			{
				timeAdded: 2,
			}
		),
	},
].map((w) => ({ ...w, __typename: 'Wanna' }))

export const notifications = wannas.map((wanna) => ({
	__typename: 'Notification',
	id: wanna.id,
	title: wanna.creator.name,
	pic: wanna.creator.pic,
	body: 'Wanna ' + wanna.activity + '?',
	path: '/app/wannas/' + wanna.id,
	timestamp: wanna.created,
	seen: null,
}))

function minutesOldDate(minutes) {
	return new Date(Date.now() - minutes * 60 * 1000)
}

function hoursOldDate(hours) {
	return new Date(Date.now() - hours * 60 * 60 * 1000)
}

function _makeConvo(messages, { timeAdded }) {
	startingMessageId += 100
	const involvedUsers = messages
		.map((m) => Object.keys(m)[0])
		.filter((element, index, array) => array.indexOf(element) === index)
	return messages.map((m, i) => ({
		__typename: 'Message',
		id: startingMessageId + i,
		sender: Object.values(users).find((u) => u.id === Object.keys(m)[0]),
		content: Object.values(m)[0],
		sent: minutesOldDate(timeAdded - i),
		seenBy: involvedUsers.map((id) =>
			Object.values(users).find((u) => u.id === id)
		),
		likes:
			Math.random() > 0.5
				? Math.floor(Math.random() * involvedUsers.length + 1)
				: 0,
	}))
}
