import { AppBar, Toolbar, Box, Button, List, Stack } from '@mui/material'
import { useNavigate } from 'react-router'
import { Blurb } from '../../components/Blurb'
import { ShareButton } from '../../components/ShareButton'
import { WannaItem } from '../../components/WannaItem'
import { useMe } from '../../hooks/useMe'
import { REFERRER_URL_PARAM } from '../../utils/constants'
import { cmpWannas } from '../../utils/utils'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import { useJoinedWannas } from '../../hooks/useJoinedWannas'

// Joined = accepted an invite, hasn't expired from inactivity,
// but can be manually closed and still appear.
export const JoinedWannas = () => {
	const me = useMe()
	const navigate = useNavigate()
	// TODO: disappears immediately from the list when we leave...
	// would be nice to remain till refresh.
	const wannas = useJoinedWannas()

	return (
		<Stack height='100%'>
			<AppBar position='static'>
				<Toolbar>
					<ToolbarTitle>chats</ToolbarTitle>
				</Toolbar>
			</AppBar>
			{wannas?.length ? (
				<List
					height='100%'
					disablePadding
					sx={{ flex: 1, overflow: 'auto' }}>
					{wannas
						?.slice()
						?.sort(cmpWannas)
						?.map((wanna) => (
							<WannaItem
								key={wanna.id}
								wanna={wanna}
								onClick={() => navigate(`/app/wannas/${wanna.id}`)}
							/>
						))}
				</List>
			) : (
				<Box
					height='100%'
					position='relative'>
					<Blurb
						text={"it's quiet in here... too quiet.\nwhy don't you:"}
						cta={
							<Stack
								alignItems='stretch'
								gap={1}>
								<Button
									variant='contained'
									color='secondary'
									onClick={() => navigate('/app/iwanna')}>
									take the initiative
								</Button>
								<ShareButton
									bigButtonText='invite friends'
									text='Come join me on Wanna!'
									link={`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`}
									sx={{ width: '100%' }}
								/>
							</Stack>
						}
					/>
				</Box>
			)}
		</Stack>
	)
}
