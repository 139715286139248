import { List } from '@mui/material'
import { useNavigate } from 'react-router'
import { WannaItem } from '../../components/WannaItem'
import { useInvitedWannas } from '../../hooks/useInvitedWannas'
import { cmpWannas } from '../../utils/utils'

// Invited = invited, hasn't expired from time, and hasn't been manually closed.
export const InvitedWannas = () => {
	const navigate = useNavigate()

	const wannas = useInvitedWannas()

	return (
		<List disablePadding>
			{wannas
				?.slice()
				?.filter((wanna) => !wanna.closed)
				?.sort(cmpWannas)
				?.map((wanna) => (
					<WannaItem
						key={wanna.id}
						wanna={wanna}
						onClick={() => navigate(`/app/wannas/${wanna.id}`)}
					/>
				))}
		</List>
	)
}
